// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    hmr: false,
    http: {
        url: 'https://backend.aws.excelway.co',
        // authentication
        login: '/authentication/signIn',
        whoami: '/authentication/whoami',
        authorizations: '/authentication/authorizations',
        stripePlans : '/authentication/stripe/plans',
        stripeAttachPaymentMethod: '/authentication/attach/pay/method',
        stripeCustomerPaymentMethods: '/authentication/pay/methods',
        stripeSubscription: '/authentication/subscribe',
        stripeRemoveSubscription: '/authentication/remove/subscription',
        stripeAddAddon: '/authentication/add/addons',
        stripeUpdatePlan: '/authentication/update/plans',
        stripeTaxes: '/authentication/tax/rates',
        // brainstorming
        brainstormingBoard: '/project/session/activity/brainstorming',
        brainstormingBoards: '/project/session/activity/brainstorming/boards',
        brainstormingBoardLevel: '/project/session/activity/brainstorming/level',
        brainstormingColumns: '/project/session/activity/brainstorming/columns',
        exportCards: '/project/session/activity/brainstorming/cards',
        brainstormingBoardImportCards: '/project/session/activity/brainstorming/cards/import',
        brainstormingAddCard: '/project/session/activity/brainstorming/add/card',
        brainstormingRemoveCard: '/project/session/activity/brainstorming/remove/card',
        brainstormingMoveCard: '/project/session/activity/brainstorming/move/card',
        brainstormingDescriptionCard: '/project/session/activity/brainstorming/update/card/description',
        brainstormingNoteCard: '/project/session/activity/brainstorming/update/card/note',
        brainstormingUpdateCard: '/project/session/activity/brainstorming/update/card',
        brainstormingAddCards: '/project/session/activity/brainstorming/cards',
        brainstormingRenameList: '/project/session/activity/brainstorming/list/name/',
        brainstormingRenameListSubtitle: '/project/session/activity/brainstorming/list/subtitle/',
        brainstormingRemoveListSubtitle: '/project/session/activity/brainstorming/list/remove/subtitle/',
        brainstormingAddList: '/project/session/activity/brainstorming/add/list',
        brainstormingRemoveList: '/project/session/activity/brainstorming/remove/list',
        brainstormingMoveList: '/project/session/activity/brainstorming/move/list',
        brainstormingAddCheckList: '/add/checklist',
        brainstormingAddcheckItem: '/card/checklist/add/checkitem',
        brainstormingRemoveCheckList: '/card/remove/checklist',
        brainstormingRemoveCheckItem: '/card/checklist/remove/checkitem',
        brainstormingCheckItemUpdate: '/card/checklist/update/checkitem',
        brainstormingLabelsUpdate: '/project/session/activity/brainstorming/labels',
        brainstormingToggleLabel: '/card/toggle/label',
        brainstormingAddLabel: '/add/label',
        brainstormingDuplicateCard: '/project/session/activity/brainstorming/duplicate/card',
        brainstormingCardDescription: '/project/session/activity/brainstorming/update/card/description',
        brainstormingAddCardComment: '/project/session/activity/brainstorming/card/comment',
        brainstormingCardComment: '/project/session/activity/brainstorming/card/remove/comment',
        brainstormingCardColor: '/project/session/activity/brainstorming/update/card/category',
        brainstormingCardsRates: '/project/session/activity/brainstorming/send/card/rates',
        brainstormingServiceUpload: '/project/import/service/session',
        brainstormingRemoveLabel: 'remove/label',
        brainstormingUpdateLabel: '/project/session/activity/brainstorming/label',
        brainstormingUpdateStarted: 'activity/brainstormStarted',
        brainstormingUpdateEvaluationStarted: 'activity/evaluationStarted',
        brainstormingUpdateTimer: '/project/session/activity/brainstorming/participative',
        brainstormingSort: '/project/session/activity/brainstorming/board/sort/list',
        brainstormingWorkflowStep: '/project/session/activity/brainstorming/workflow',
        brainstormingSettings: '/project/session/activity/brainstorming/update/board/settings',
        // canvas
        canvasBoard: '/project/session/activity/canvas',
        canvasTemplate: '/project/studio/template',
        canvasUpdateTemplate: '/project/studio/update/template',
        canvasDuplicateTemplate: '/project/studio/template/duplicate',
        canvasDeleteTemplate: '/project/studio/delete/template',
        canvasBoards: '/project/session/activity/canvas/boards',
        canvasBoardLevel: '/project/session/activity/canvas/level',
        canvasColumns: '/project/session/activity/canvas/columns',
        exportCanvasCards: '/project/session/activity/canvas/cards',
        canvasAddCard: '/project/session/activity/canvas/add/card',
        canvasRemoveCard: '/project/session/activity/canvas/remove/card',
        canvasBoardImportCards: '/project/session/activity/canvas/cards/import',
        canvasMoveCard: '/project/session/activity/canvas/move/card',
        canvasDescriptionCard: '/project/session/activity/canvas/update/card/description',
        canvasNoteCard: '/project/session/activity/canvas/update/card/note',
        canvasUpdateCard: '/project/session/activity/canvas/update/card',
        canvasAddCards: '/project/session/activity/canvas/cards',
        canvasRenameList: '/project/session/activity/canvas/list/name/',
        canvasRenameListSubtitle: '/project/session/activity/canvas/list/subtitle/',
        canvasRemoveListSubtitle: '/project/session/activity/canvas/list/remove/subtitle/',
        canvasAddList: '/project/session/activity/canvas/add/list',
        canvasRemoveList: '/project/session/activity/canvas/remove/list',
        canvasMoveList: '/project/session/activity/canvas/move/list',
        canvasAddCheckList: '/add/checklist',
        canvasAddcheckItem: '/card/checklist/add/checkitem',
        canvasRemoveCheckList: '/card/remove/checklist',
        canvasRemoveCheckItem: '/card/checklist/remove/checkitem',
        canvasCheckItemUpdate: '/card/checklist/update/checkitem',
        canvasLabelsUpdate: '/project/session/activity/canvas/labels',
        canvasToggleLabel: '/card/toggle/label',
        canvasDuplicateCard: '/project/session/activity/canvas/duplicate/card',
        canvasCardDescription: '/project/session/activity/canvas/update/card/description',
        canvasAddCardComment: '/project/session/activity/canvas/card/comment',
        canvasCardComment: '/project/session/activity/canvas/card/remove/comment',
        canvasCardColor: '/project/session/activity/canvas/update/card/category',
        canvasCardsRates: '/project/session/activity/canvas/send/card/rates',
        canvasServiceUpload: '/project/import/service/session',
        canvasRemoveLabel: 'remove/label',
        canvasUpdateTimer: '/project/session/activity/canvas/participative',
        canvasSort: '/project/session/activity/canvas/board/sort/list',
        canvasUpdateLabel: '/project/session/activity/canvas/label',
        canvasAddLabel: '/add/label',
        canvasWorkflowStep: '/project/session/activity/canvas/workflow',
        canvasSettings: '/project/session/activity/canvas/update/board/settings',
        // cardboard
        result: '/project/session/result',
        // service
        service: '/service',
        serviceImport: '/service/import',
        // project
        project: '/project',
        projectList: '/project/projects',
        projectChild: '/project/child',
        projectOne: '/project/details',
        projectArchive: '/project/archive',
        projectLevels: '/project/level',
        projectLevelExist: '/project/level/exist',
        projectDetail: '/project/detail',
        projectDocument: '/project/document',
        projectBoardArchive: '/project/board/archive',
        projectUsers: '/project/user/list',
        projectServices: '/project/service/list',
        projectBySessionToken: '/project/one/session/token',
        projectTree: '/project/tree',
        // project session
        projectSessions: '/project/session',
        projectSession: '/project/session',
        projectSessionByToken: '/project/session/token',
        projectSessionDetails: '/project/session',
        projectSessionStatus: '/project/session/status',
        projectSessionUsers: '/project/session/user/list',
        ProjectSessionDetails: '/project/session/detail',
        ProjectSessionContent: '/project/session/version',
        projectSessionDuplicate: '/project/session/duplicate',
        ProjectSessionContents: '/project/session/versions',
        ProjectSessionArchive: '/project/session/archive',
        projectSessionParticipants: '/project/session/participant/list',
        projectSessionGuests: '/project/session/guest/list',
        projectSessionUpdateParticipantsAndSendInvitation:
            '/project/session/participant/invitation',
        projectSessionUpdateGuestsAndSendInvitation:
            '/project/session/guest/invitation',
        projectSessionInvitation: '/project/session/invitation',
        projectSessionActionplan: '/project/session/task',
        projectSessionActionplans: '/project/session/tasks',
        projectSessionResults: '/project/session/result',
        projectSessionResultsChart: '/project/session/result/chart',
        projectSessionType: '/project/session/check',
        projectSessionNewUser: '/project/session/public/participant',
        projectSessionHome: '/project/session/home/page',
        projectSessionNote: '/project/session/note',
        projectSessionActivity: '/project/session/activity',
        projectSessionInfo: '/project/session/info',
        projectSessionPrivacy: '/project/session/privacy',
        projectSessionDuplicateActivity: 'activity/duplicate',
        projectSessionNotes: '/session/notes',
        // session workshop
        toggleAnimator: '/toggle/animator',
        toggleRole: '/project/session/change/role',
        addUsersByRole: '/project/session/user/add/role',
        removeUserByRole: '/project/session/user/remove/role',
        removeTask: '/project/session/remove/task',
        // project board
        projectBoards: '/project/board',
        projectBoardCards: '/project/board/cards',
        projectBoard: '/project/board',
        projectBoardMembers: '/project/board/user/list',
        projectBoardDetails: '/project/board/info',
        projectBoardExportTasks: '/project/board/cards',
        projectBoardAddCardComment: '/project/board/card/comment',
        projectBoardRename: '/project/board/name',
        projectBoardAddCard: '/project/board/add/card',
        projectBoardRemoveCard: '/project/board/remove/card',
        projectBoardRemoveCardFromBoard: '/project/board/remove/shared/card',
        projectBoardMoveCard: '/project/board/move/card',
        projectBoardUpdateCard: '/project/board/update/card',
        projectBoardUnsetResponsible: '/project/board/unset/card/responsible',
        projectBoardCardActivities: '/project/board/card/activities',
        projectBoardDeleteCardDate: '/project/board/delete/card/date',
        projectBoardRenameList: '/project/board/list/name/',
        projectBoardAddList: '/project/board/add/list',
        projectBoardRemoveList: '/project/board/remove/list',
        projectBoardMoveList: '/project/board/move/list',
        projectBoardToggleMember: '/card/toggle/member',
        projectBoardAddCheckList: '/add/checklist',
        projectBoardAddcheckItem: '/card/checklist/add/checkitem',
        projectBoardRemoveCheckList: '/card/remove/checklist',
        projectBoardUpdateChecklistName: '/card/update/checklistName',
        projectBoardRemoveCheckItem: '/card/checklist/remove/checkitem',
        ProjectBoardCheckItemUpdate: '/card/checklist/update/checkitem',
        ProjectBoardLabelsUpdate: '/project/board/labels',
        ProjectBoardToggleLabel: '/card/toggle/label',
        ProjectBoardDuplicateCard: '/project/board/duplicate/card',
        ProjectBoardCardDescription: '/project/board/update/card/description',
        ProjectBoardCardComment: '/project/board/card/remove/comment',
        ProjectBoardAttachment: '/project/board/card/attachment',
        ProjectBoardRemoveLabels: 'remove/label',
        ProjectBoardLabelUpdate: '/project/board/label',
        ProjectBoardLabelMove: '/project/board/move/card/label',
        ProjectBoardAddLabel: '/add/label',
        ProjectBoardUpdateBackground: '/project/board/background',
        projectBoardTemplates: '/project/board/excelway/templates',
        projectAddBoardTemplate: '/project/board/from/template',
        projectSaveBoardAsTemplate: '/project/board/toggle/template',
        projectCardtoBoard: '/project/board/set/card/link',
        projectMoveCardtoBoard: '/project/board/export/card',
        projectAddCardtoBoard: '/project/board/share/card',
        projectUnlinkCardFromBoard: '/project/board/remove/card/link',
        projectBoardCharts: '/project/board/charts',
        projectBoardAddWidget: '/project/board/add/widget',
        projectBoardUpdateWidget: '/project/board/update/widget',
        projectBoardMoveWidget: '/project/board/move/widget',
        projectBoardRemoveWidget: '/project/board/remove/widget',
        // notifications
        standbyCards: '/project/board/standbycard/list',
        standbyCardsIsRead: '/project/board/standbycard/isread',
        standbyCardsStatus: '/project/board/standbycard/status',
        weeklyNotifications: '/notification/weekly/summaries',
        dailyNotifications: '/notification/daily/summaries',
        // attachment
        attachment: '/attachment',
        removeAttachment: '/project/board/card/remove/attachment',
        updateAsCover: '/project/board/update/card/cover',
        // users
        user: '/user',
        userAddFavorite: '​/user/permission/add/favorite',
        userRemoveFavorite: '/user/permission/remove/favorite',
        userResetPassword: '/user/password',
        userInformations: '/user/information',
        userOccupation: '/user/occupation',
        userContact: '/user/contact',
        userSignUp: '/user/signup',
        userLang: '/user/language',
        watchBoard: '/user/board/watch',
        userProfilePic: '/user/profilePic',
        userChangePasword: '/user/change/password',
        userNotice: '/user/summaryParams',
        userInAppNotifications: '/user/get/inAppNotifications',
        readInAppNotification: '/user/inAppNotification/read',
        userTimezone: '/user/timeZone',
        stripeCheckout: '/authentication/create/checkout/session',
        // report
        report: '/report',
        reportCardBoard: '/project/session/report/cardboard',
        reportNotes: '/project/session/report/notes',
        reportGlobal: '/project/session/report/global'
    },
    socket: {
        //http://localhost:3000?apikey=mE6dv4ZLP6aZ
        url: 'https://socket-io.aws.excelway.co',
        apikey: 'nCvrHw7HZvNR5sxf',
    },
    sentry: {
        dsn: 'https://a96d01a65b0d48cbbabc3aa94fb09a6a@o1016896.ingest.sentry.io/5982380',
        release: 'excelway-frontend@1.0.0',
        environment: 'aws-production',
    },
    report: {
        url: 'https://pdf-generator.aws.excelway.co',
    },
    mixpanel: {
        id: '43a43df52bcfe2ff43901e2ede9bf236'
    },
    minio: {
        url: 'https://hasura-backend-plus.staging.bsuccess.ma'
    },
    temporaryTenant: 'beta'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
